export default function ScrollToTop({ scrolled }) {
  return (
    <div
      className="fixed bottom-8 right-8 z-30 opacity-0 duration-200 cursor-pointer bg-[#2f3c4f] w-[3rem] h-[3rem] rounded-full hover:-translate-y-2"
      style={{
        opacity: `${scrolled ? '1' : '0'}`,
      }}>
      <a className="w-full h-full flex justify-center items-center" href={`#`}>
        <svg
          className=" w-[60%] h-[60%]"
          fill="#000000"
          height="800px"
          width="800px"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 330 330"
          xmlSpace="preserve">
          <path
            fill="#fff"
            id="XMLID_29_"
            d="M100.606,100.606L150,51.212V315c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15V51.212l49.394,49.394
	C232.322,103.535,236.161,105,240,105c3.839,0,7.678-1.465,10.606-4.394c5.858-5.857,5.858-15.355,0-21.213l-75-75
	c-5.857-5.858-15.355-5.858-21.213,0l-75,75c-5.858,5.857-5.858,15.355,0,21.213C85.251,106.463,94.749,106.463,100.606,100.606z"
          />
        </svg>
      </a>
    </div>
  );
}
