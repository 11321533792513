import { lazy } from "react";

// import Hero from "./Hero";
// import About from "./About";
// import Tech from "./Tech";
// import Experience from "./Experience";
// import Works from "./Works";
// import Contact from "./Contact";
// import Footer from "./Footer";

const Hero = lazy(() => import("./Hero"));
const About = lazy(() => import("./About"));
const Tech = lazy(() => import("./Tech"));
const Courses = lazy(() => import("./Courses"));
const Works = lazy(() => import("./Works"));
const Contact = lazy(() => import("./Contact"));
const Footer = lazy(() => import("./Footer"));

export { Hero, About, Tech, Courses, Works, Contact, Footer };
