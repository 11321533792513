import { Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import { AppLoader, Navbar } from "./components";

import { Hero, About, Tech, Courses, Works, Contact, Footer } from "./pages";

const App = () => {
  // Fix the passive scroll
  useEffect(() => {
    const scrollTouchStartHandler = (e) => {
      e.preventDefault();
    };

    window.addEventListener("touchstart", scrollTouchStartHandler, {
      passive: true,
    });

    return () => {
      window.removeEventListener("touchstart", scrollTouchStartHandler, {
        passive: true,
      });
    };
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<AppLoader />}>
        {/* <Cursor /> */}
        <div className="relative z-0 bg-primary scrollbar">
          <div className="bg-hero-pattern bg-cover bg-no-repeat bg-center">
            <Navbar />
            <Hero />
          </div>
          <About />
          <Courses />
          <Tech />
          <Works />
          <div className="relative z-0">
            <Contact />
          </div>
          <Footer />
        </div>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
