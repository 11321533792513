import { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { motion, useScroll } from "framer-motion";

import ScrollToTop from "./ScrollToTop";
import { styles } from "../styles";
import { navLinks } from "../constants";
import { logo } from "../assets";

const topSpanStyle = {
  showMenu: "top-[50%] -rotate-[45deg]",
  hideMenu: "rotate-0 top-[25%]",
};

const bottomSpanStyle = {
  showMenu: "top-[50%] rotate-[45deg]",
  hideMenu: "rotate-0 top-[75%]",
};

const spanStyle =
  " w-[70%] h-[1px] border absolute left-[50%] -translate-y-[1px] -translate-x-1/2 duration-150";

const Navbar = () => {
  const { scrollYProgress } = useScroll();

  const [active, setActive] = useState("");
  const [toggle, setToggle] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Fragment>
      <nav
        className={`${
          styles.paddingX
        } w-full flex items-center py-5 fixed top-0 z-20 bg-primary ${
          scrolled ? "bg-primary" : "bg-transparent"
        } transition ease-in-out duration-300`}
      >
        <motion.div
          style={{
            scaleX: scrollYProgress,
            background:
              "radial-gradient(circle at 6.6% 12%, rgb(16, 13, 37) 20.8%, rgb(0, 255, 160) 100.2%)",
          }}
          className="fixed top-0 left-0 right-0 h-2 z-50 origin-[0%]"
        />
        <div className="w-full flex justify-between items-center max-w-7xl mx-auto">
          <Link
            to={"/"}
            className="flex items-center gap-2"
            onClick={() => {
              setActive("");
              window.scrollTo(0, 0);
            }}
          >
            <img
              src={logo}
              loading="lazy"
              alt="logo"
              className="w-12 h-12 object-contain"
            />
            <p className="text-white text-[18px] font-bold cursor-pointer">
              Noah Alvandi&nbsp;
              <span className="sm:block hidden"> Web Developer</span>
            </p>
          </Link>
          <ul className="list-none hidden sm:flex flex-row gap-10">
            {navLinks.map((link) => (
              <li
                key={link.id}
                className={`${
                  active === link.title ? "text-white" : "text-secondary"
                } hover:text-white text-[18px] font-medium cursor-pointer `}
                onClick={() => setActive(link.title)}
              >
                <a href={`#${link.id}`}>{link.title}</a>
              </li>
            ))}
          </ul>

          <div className="sm:hidden flex flex-1 justify-end items-center">
            {/* hamburger manu */}
            <div
              className={`w-[2rem] h-[2rem] cursor-pointer relative`}
              onClick={() => setToggle(!toggle)}
            >
              <span
                className={`${spanStyle} ${
                  toggle ? topSpanStyle.showMenu : topSpanStyle.hideMenu
                }`}
              ></span>
              <span
                className={`${spanStyle} top-[50%]`}
                style={{ display: `${toggle ? "none" : "block"}` }}
              ></span>
              <span
                className={`${spanStyle} ${
                  toggle ? bottomSpanStyle.showMenu : bottomSpanStyle.hideMenu
                }`}
              ></span>
            </div>
            <div
              className={`${
                !toggle ? "hidden" : "flex"
              } p-6 black-gradient absolute top-20 right-0 mx-4 my-2 min-w[140px] z-10 rounded-xl`}
            >
              <ul className="list-none flex justify-end items-start flex-col gap-4">
                {navLinks.map((link) => (
                  <li
                    key={link.id}
                    className={`${
                      active === link.title ? "text-white" : "text-secondary"
                    } font-poppins font-medium text-[16px] `}
                    onClick={() => {
                      setActive(link.title);
                      setToggle(!toggle);
                    }}
                  >
                    <a href={`#${link.id}`}>{link.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <ScrollToTop scrolled={scrolled} />
    </Fragment>
  );
};

export default Navbar;
